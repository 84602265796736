import { filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { DammaClass } from '../../@types/damma-class';
import { ClassSummary } from '../../@types/damma-reports';
// ----------------------------------------------------------------------

type ClassListState = {
  isLoading: boolean;
  error: boolean;
  classList: ClassSummary[];
  // classList: DammaClass[];
};

const initialState: ClassListState = {
  isLoading: false,
  error: false,
  classList: []
};

// TODO: implement appropriate reducers after finding out what the app actually needs
const slice = createSlice({
  name: 'classes',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CLASSES
    getClassesSuccess(state, action) {
      state.isLoading = false;
      state.classList = action.payload;
    },

    // CREATE CLASS
    createClass(state, action) {
      state.isLoading = false;
      state.classList.push(action.payload);
    },

    // MODIFY CLASS
    modifyClass(state, action) {
      state.isLoading = false;
      // const modifiedClasses = state.classList.map((c) => {
      //   if (c.classID !== action.payload.classID) {
      //     return c;
      //   }
      //   return action.payload;
      // });
      // state.classList = modifiedClasses;
    },

    // DELETE CLASS
    deleteClass(state, action) {
      state.isLoading = false;
      state.classList = filter(state.classList, (c) => c.classID !== action.payload);
      // TODO: do we need to implement isLoading here?
      // state.classList = deleteClass;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions

// SAMIR: we can export actions directly from the slice if they have no more details

// ----------------------------------------------------------------------
export function getClasses(schoolID: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`getSchoolClasses/${schoolID}`);
      const classes: DammaClass[] = response.data;
      for (let c of classes) {
        c.schoolID = schoolID;
        if (c.teachersNames) c.teachers = c.teachersNames.split(',');
        else c.teachers = [];
      }
      dispatch(slice.actions.getClassesSuccess(classes));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createClass(newClass: DammaClass) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      console.log(newClass);
      const response = await axios.post('addClass', newClass);
      const r = response.data;
      r.classID = r.id;
      dispatch(slice.actions.createClass(r));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function modifyClass(newClass: DammaClass) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`classes/${newClass.classID}`, newClass);
      const r = response.data;
      dispatch(slice.actions.modifyClass(r));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteClass(classID: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`classes/${classID}`);
      dispatch(slice.actions.deleteClass(classID));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------
