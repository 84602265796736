// material
import { Grid, Button, Divider, Typography } from '@mui/material';
// hooks
import useAuth from '../../hooks/useAuth';
import useLocales from '../../hooks/useLocales';
// ----------------------------------------------------------------------

export default function AuthFirebaseSocial() {
  const { loginWithGoogle, loginWithApple } = useAuth();
  const { translate } = useLocales();

  const handleLoginGoogle = async () => {
    try {
      await loginWithGoogle?.();
    } catch (error) {
      console.error(error);
    }
  };

  const handleLoginApple = async () => {
    try {
      await loginWithApple?.();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs>
          <Button
            fullWidth
            size="large"
            color="inherit"
            variant="outlined"
            onClick={handleLoginGoogle}
          >
            <img src={'/static/icons/ic_google.svg'} alt="Google Icon" height="24" />
            {/* <Icon icon={googleFill} color="#DF3E30" height={24} /> */}
          </Button>
        </Grid>
        <Grid item xs>
          <Button
            fullWidth
            size="large"
            color="inherit"
            variant="outlined"
            onClick={handleLoginApple}
          >
            {/* <Icon icon={appleFill} color="#7D7D7D" height={24} /> */}
            <img src={'/static/icons/ic_apple.svg'} alt="Apple Icon" height="24" />
          </Button>
        </Grid>
        {/* <Grid item xs>
          <Button
            fullWidth
            size="large"
            color="inherit"
            variant="outlined"
            onClick={handleLoginFaceBook}
          >
            <Icon icon={facebookFill} color="#1877F2" height={24} />
          </Button>
        </Grid>
        <Grid item xs>
          <Button
            fullWidth
            size="large"
            color="inherit"
            variant="outlined"
            onClick={handleLoginTwitter}
          >
            <Icon icon={twitterFill} color="#1C9CEA" height={24} />
          </Button>
        </Grid> */}
      </Grid>

      <Divider sx={{ my: 3 }}>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {translate('AuthFirebaseSocial.OR')}
        </Typography>
      </Divider>
    </>
  );
}
