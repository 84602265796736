import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { ClassSummary, Student, LessonClassSummary } from '../../@types/damma-reports';
// ----------------------------------------------------------------------

type ClassListState = {
  isLoading: boolean;
  error: boolean;
  classesList: ClassSummary[];
  userList: Student[];
  activeClass: ClassSummary | null;
  lessonReport: LessonClassSummary | null;
};

const initialState: ClassListState = {
  isLoading: false,
  error: false,
  classesList: [],
  userList: [],
  activeClass: null,
  lessonReport: null
};

// TODO: implement appropriate reducers after finding out what the app actually needs
const slice = createSlice({
  name: 'classes',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.error = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CLASSES
    getClassesSuccess(state, action) {
      state.isLoading = false;
      state.classesList = action.payload;
    },

    // GET CLASS STUDENTS
    getClassUsersSuccess(state, action) {
      state.isLoading = false;
      state.userList = action.payload;
    },

    getClassStatsPerLesson(state, action) {
      state.isLoading = false;
      state.lessonReport = action.payload;
    },

    setActiveClassSuccess(state, action) {
      state.activeClass = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions

// SAMIR: we can export actions directly from the slice if they have no more details

// ----------------------------------------------------------------------
export function getTeacherClasses(schoolID: number, UID: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      console.log('getTeacherClasses');
      const response = await axios.get(`getUserClasses/${schoolID}/${UID}`);
      dispatch(slice.actions.getClassesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function getClassUsers(schoolID: number, ClassID: number | string, objectType: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`getClassUsers/${schoolID}/${ClassID}/${objectType}`);
      dispatch(slice.actions.getClassUsersSuccess(response.data));
    } catch (error) {
      console.log('ERROR: ', error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function getClassStatsPerLesson(
  schoolID: number,
  ClassID: number | string,
  LessonID: string
) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `getClassUserStatsPerLesson/${schoolID}/${ClassID}/${LessonID}`
      );
      dispatch(
        slice.actions.getClassStatsPerLesson({
          students: response.data.students,
          classID: ClassID,
          className: response.data.className,
          lessonName: response.data.lessonName,
          lessonID: LessonID,
          level: response.data.level
        })
      );
    } catch (error) {
      console.log('ERROR: ', error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setActiveClass(classObject: ClassSummary) {
  return async () => {
    dispatch(slice.actions.setActiveClassSuccess(classObject));
  };
}
