import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import blogReducer from './slices/blog';
import userReducer from './slices/user';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import classesReducer from './slices/classes';
import usersReducer from './slices/users';
import reportsReducer from './slices/reports';
import schoolReducer from './slices/school';
import accountReducer from './slices/account';
import subscriptionReducer from './slices/subscription';
import enrollReducer from './slices/enroll';
import adminReportsReducer from './slices/admin-reports';
import teacherReducer from './slices/teacher';
import supervisorReducer from './slices/supervisor';

// ----------------------------------------------------------------------

// TODO: clean up unused reducers and connect appropriate APIs

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
};

const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  blog: blogReducer,
  user: userReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  product: persistReducer(productPersistConfig, productReducer),
  classes: classesReducer,
  users: usersReducer,
  reports: reportsReducer,
  school: schoolReducer,
  account: accountReducer,
  subscription: subscriptionReducer,
  enroll: enrollReducer,
  adminReports: adminReportsReducer,
  teacher: teacherReducer,
  supervisor: supervisorReducer
});

export { rootPersistConfig, rootReducer };
