import axios from 'axios';
import { g } from '../config';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: g.serverURL
});

axiosInstance.interceptors.response.use(
  (response) => response,
  // (error) => console.error(error),
  (error) => Promise.reject((error.response && error.response.data) || console.error(error))
);

export default axiosInstance;
