import { ReactNode } from 'react';
import { Container, Alert, AlertTitle } from '@mui/material';
// redux
import { useSelector, RootState } from '../redux/store';
// hooks
import useLocales from '../hooks/useLocales';

// ----------------------------------------------------------------------

type RoleBasedGuardProp = {
  accessibleRoles: string[];
  children: ReactNode | string;
};

export default function RoleBasedGuard({ accessibleRoles, children }: RoleBasedGuardProp) {
  const { translate } = useLocales();
  const { account } = useSelector((state: RootState) => state.account);
  let role: string = 'student';
  if (!(!account || !account.role || account.role.length === 0)) {
    role = account.role;
  }
  if (!accessibleRoles.includes(role)) {
    return (
      <Container>
        <Alert severity="error">
          <AlertTitle>{translate('RoleBasedGuard.Permission Denied')}</AlertTitle>
          {translate('RoleBasedGuard.noAccess')}
        </Alert>
      </Container>
    );
  }

  return <>{children}</>;
}
