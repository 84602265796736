import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { AdminStats, TopStudent } from '../../@types/admin-reports';
// ----------------------------------------------------------------------

type AdminReportsState = {
  isLoading: boolean;
  error: boolean;
  stats: AdminStats;
  top5Users: TopStudent[];
};

const initialState: AdminReportsState = {
  isLoading: false,
  error: false,
  stats: {
    totalPromos: 0,
    totalRegStudents: 0,
    totalUnEnrolledStudents: 0,
    totalRegTeachers: 0,
    totalUnEnrolledTeachers: 0,
    UnAssignedClassCount: 0,
    classCount: 0,
    promosRemaining: 0
  },
  top5Users: []
};

// TODO: implement appropriate reducers after finding out what the app actually needs
const slice = createSlice({
  name: 'adminReports',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getStatsSuccess(state, action) {
      // only set it to false after both calls are successfull
      // state.isLoading = false;
      state.stats = action.payload;
    },

    getTop5Success(state, action) {
      state.isLoading = false;
      state.top5Users = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions

// ----------------------------------------------------------------------
export function getSchoolStats(schoolID: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`getSchoolStats/${schoolID}`);
      dispatch(slice.actions.getStatsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function getTop5Users(schoolID: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`getTop5UsersByDurationPerSchool/${schoolID}`);
      dispatch(slice.actions.getTop5Success(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
