import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from 'utils/axios';
import { Account, AccountUpdate } from '../../@types/account';

// ----------------------------------------------------------------------

type AccountState = {
  isLoading: boolean;
  error: boolean;
  account: null | Account;
};

const initialState: AccountState = {
  isLoading: false,
  error: false,
  account: null
};

const slice = createSlice({
  name: 'Account',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET/SET Account
    getAccountSuccess(state, action) {
      state.isLoading = false;
      state.account = action.payload;
    },

    // here only part of the data are updated
    setAccountSuccess(state, action) {
      state.isLoading = false;
      if (state.account) {
        state.account = {
          ...state.account,
          displayName: action.payload.displayName,
          gender: action.payload.gender,
          imageURL: action.payload.imageURL
        };
      } else {
        state.account = action.payload;
      }
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getAccount(
  UID: string,
  displayName?: string | null,
  email?: string | null,
  imageURL?: string | null
) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      console.log('getAccount');
      const response = await axios.post(`regOrLogin`, { UID, displayName, email, imageURL });
      dispatch(slice.actions.getAccountSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setAccount(UID: string, account: AccountUpdate) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      axios.put('updateUserInfo', {
        UID,
        displayName: account.displayName,
        gender: account.gender,
        imageURL: account.imageURL
      });
      dispatch(slice.actions.setAccountSuccess(account));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
