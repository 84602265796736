//
import { MAvatar } from './@material-extend';
import { MAvatarProps } from './@material-extend/MAvatar';
import createAvatar from '../utils/createAvatar';
// redux
import { useSelector, RootState } from '../redux/store';
// utils
import { avatarURL } from '../utils/avatars';
// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }: MAvatarProps) {
  const { account } = useSelector((state: RootState) => state.account);
  const displayName = account?.displayName ? account?.displayName : '';
  const url = avatarURL(account?.imageURL);
  return (
    <MAvatar
      src={url}
      alt={displayName}
      color={account?.imageURL ? 'default' : createAvatar(displayName).color}
      {...other}
    >
      {createAvatar(displayName).name}
    </MAvatar>
  );
}
