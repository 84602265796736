// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_TEACHER = '/teacher';
const ROOTS_STUDENT = '/reports';
const ROOTS_SUPER_DASHBOARD = '/dashboard/supervisor';

// ----------------------------------------------------------------------

// SAMIR: added admin routes
// export const PATH_ADMIN = {
//   root: path(ROOTS_ADMIN, '/home'),
//   classes: path(ROOTS_ADMIN, '/classes'),
//   classesNew: path(ROOTS_ADMIN, '/classes/create'),
//   classesModify: path(ROOTS_ADMIN, '/classes'),
//   classesDetails: path(ROOTS_ADMIN, '/classes/details'),
//   users: path(ROOTS_ADMIN, '/users'),
//   school: path(ROOTS_ADMIN, '/scool'),
//   account: path(ROOTS_ADMIN, '/account')
// };

// SAMIR: added teacher routes
export const PATH_TEACHER = {
  classDetails: path(ROOTS_DASHBOARD, '/class/:classID'),
  root: path(ROOTS_TEACHER, '/home'),
  classes: path(ROOTS_TEACHER, '/classes'),
  // classDetails: path(ROOTS_TEACHER, '/classes/:id'),
  reportHome: path(ROOTS_TEACHER, '/reports'),
  report: path(ROOTS_TEACHER, '/reports/:classId/:lessonId')
};

// SAMIR: added student routes
export const PATH_STUDENT = {
  root: path(ROOTS_STUDENT, '/all'),
  generate: path(ROOTS_STUDENT, '/generate'),
  lesson: path(ROOTS_STUDENT, '/:lessonId')
};

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
    users: path(ROOTS_DASHBOARD, '/users')
  },
  admin: {
    app: path(ROOTS_DASHBOARD, '/admin'),
    school: path(ROOTS_DASHBOARD, '/school'),
    classes: path(ROOTS_DASHBOARD, '/classes'),
    classesNew: path(ROOTS_DASHBOARD, '/classes/create'),
    classesModify: path(ROOTS_DASHBOARD, '/classes'),
    classesEnroll: path(ROOTS_DASHBOARD, '/classes/enroll'),
    users: path(ROOTS_DASHBOARD, '/users')
  },
  supervisor: {
    app: path(ROOTS_SUPER_DASHBOARD, '/app'),
    promos: path(ROOTS_SUPER_DASHBOARD, '/promos'),
    users: path(ROOTS_SUPER_DASHBOARD, '/users'),
    subscription: path(ROOTS_SUPER_DASHBOARD, '/subscriptions'),
    newPromo: path(ROOTS_SUPER_DASHBOARD, '/promos/new'),
    schools: path(ROOTS_SUPER_DASHBOARD, '/schools')
  },

  //TODO: remove unecessary
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all')
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    conversation: path(ROOTS_DASHBOARD, '/chat/:conversationKey')
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, '/user/account')
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    product: path(ROOTS_DASHBOARD, '/e-commerce/product/:name'),
    productById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    newProduct: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    editById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    invoice: path(ROOTS_DASHBOARD, '/e-commerce/invoice')
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    post: path(ROOTS_DASHBOARD, '/blog/post/:title'),
    postById: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
    newPost: path(ROOTS_DASHBOARD, '/blog/new-post')
  }
};

export const PATH_DAMMA_WEBSITE = 'https://www.damma.app/';
export const PATH_DOCS = 'https://www.damma.app/faq';
export const PATH_TERMS = 'https://www.damma.app/terms';
export const PATH_PRIVACY = 'https://www.damma.app/privacy';
