import { createContext, ReactNode, useEffect, useReducer } from 'react';
import { firebaseConfig } from '../config';
import firebase from 'firebase/app';
import 'firebase/auth';
// @types
import { ActionMap, AuthState, AuthUser, FirebaseContextType } from '../@types/authentication';
//
import { setSession } from 'utils/jwt';
import { getAccount } from 'redux/slices/account';
import { getAllLessons } from 'redux/slices/reports';
import { dispatch as storeDispatch } from 'redux/store';
// ----------------------------------------------------------------------

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

enum Types {
  Initial = 'INITIALISE'
}

type FirebaseAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    isVerified: boolean;
    user: AuthUser;
  };
};

type FirebaseActions = ActionMap<FirebaseAuthPayload>[keyof ActionMap<FirebaseAuthPayload>];

const reducer = (state: AuthState, action: FirebaseActions) => {
  if (action.type === 'INITIALISE') {
    const { isAuthenticated, user, isVerified } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      isVerified,
      user
    };
  }

  return state;
};

const AuthContext = createContext<FirebaseContextType | null>(null);

var newDisplayName: string | null;

function AuthProvider({ children }: { children: ReactNode }) {
  // const isMountedRef = useIsMountedRef();
  // const [profile, setProfile] = useState<IUserInfo | undefined>();
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(
    () =>
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          if (newDisplayName == null) newDisplayName = user.displayName;
          user
            .getIdToken()
            .then(async (idToken) => {
              await setSession(idToken);
              storeDispatch(getAccount(user?.uid, newDisplayName, user?.email, user?.photoURL));
              storeDispatch(getAllLessons());
              dispatch({
                type: Types.Initial,
                payload: { isAuthenticated: true, isVerified: user.emailVerified, user }
              });
            })
            .catch((error) => {
              console.log('AUTH ERROR: ', error);
            });
        } else {
          dispatch({
            type: Types.Initial,
            payload: { isAuthenticated: false, isVerified: false, user: null }
          });
        }
      }),
    []
  );

  const login = (email: string, password: string) =>
    firebase.auth().signInWithEmailAndPassword(email, password);

  const loginWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };

  const loginWithApple = () => {
    const provider = new firebase.auth.OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('name');
    return firebase.auth().signInWithPopup(provider);
  };

  const loginWithFaceBook = () => {
    const provider = new firebase.auth.FacebookAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };

  const loginWithTwitter = () => {
    const provider = new firebase.auth.TwitterAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };

  const register = (email: string, password: string, displayName: string) => {
    newDisplayName = displayName;
    return firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(() => {
        verifyEmail();
      });
  };

  const logout = async () => {
    await firebase.auth().signOut();

    //remove token from client
    setSession(null);
  };

  const resetPassword = async (email: string) => {
    await firebase.auth().sendPasswordResetEmail(email);
  };

  const verifyEmail = async () => {
    const user = firebase.auth().currentUser;
    if (!user?.emailVerified) {
      await user?.sendEmailVerification();
      return false;
    } else return true;
  };

  const updatePassword = async (oldPassword: string, newPassword: string) => {
    const user = firebase.auth().currentUser;
    const email = user?.email || '';
    const credential = firebase.auth.EmailAuthProvider.credential(email, oldPassword);
    const res = await user?.reauthenticateWithCredential(credential);
    if (res) user?.updatePassword(newPassword);
  };

  const auth = { ...state.user };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'firebase',
        user: {
          UID: auth.uid,
          email: auth.email,
          emailVerified: auth.emailVerified,
          displayName: auth.displayName,
          photoURL: 'http://b01:3000/static/mock-images/avatars/avatar_1.jpg'
        },
        login,
        register,
        loginWithGoogle,
        loginWithApple,
        loginWithFaceBook,
        loginWithTwitter,
        logout,
        verifyEmail,
        resetPassword,
        updatePassword,
        updateProfile: () => {}
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
