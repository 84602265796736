// material
// import { useTheme } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

export default function LogoFull({ sx }: BoxProps) {
  return (
    <Box
      component="img"
      alt="logo"
      src="/static/full_logo.png"
      sx={{ width: 95, height: 40, ...sx }}
    />
  );
}
