import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { MHidden } from 'components/@material-extend';
import { alpha, styled } from '@mui/material/styles';
import {
  Box,
  Link,
  Stack,
  Drawer,
  Tooltip,
  Typography,
  CardActionArea,
  Avatar
} from '@mui/material';
// hooks
import useLocales from 'hooks/useLocales';
import useCollapseDrawer from 'hooks/useCollapseDrawer';
// redux
import { useSelector, RootState, useDispatch } from 'redux/store';
import { getTeacherClasses } from 'redux/slices/teacher';
// routes
import { PATH_DASHBOARD } from 'routes/paths';
// components
import LogoFull from 'components/LogoFull';
import MyAvatar from 'components/MyAvatar';
import Scrollbar from 'components/Scrollbar';
import NavSection from 'components/NavSection';
//
import { sidebarConfigAdmin, sidebarConfigStudent, sidebarConfigSupervisor } from './SidebarConfig';
import { Icon } from '@iconify/react';
// utils
import { classAvatarURL } from 'utils/avatars';
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.complex
    })
  }
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[500_12]
}));

// ----------------------------------------------------------------------

type IconCollapseProps = {
  onToggleCollapse: VoidFunction;
  collapseClick: boolean;
};

function IconCollapse({ onToggleCollapse, collapseClick }: IconCollapseProps) {
  return (
    <Tooltip title="Mini Menu">
      <CardActionArea
        onClick={onToggleCollapse}
        sx={{
          width: 18,
          height: 18,
          display: 'flex',
          cursor: 'pointer',
          borderRadius: '50%',
          alignItems: 'center',
          color: 'text.primary',
          justifyContent: 'center',
          border: 'solid 1px currentColor',
          ...(collapseClick && {
            borderWidth: 2
          })
        }}
      >
        <Box
          sx={{
            width: 8,
            height: 8,
            borderRadius: '50%',
            bgcolor: 'currentColor',
            transition: (theme) => theme.transitions.create('all'),
            ...(collapseClick && {
              width: 0,
              height: 0
            })
          }}
        />
      </CardActionArea>
    </Tooltip>
  );
}

type DashboardSidebarProps = {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }: DashboardSidebarProps) {
  const { pathname } = useLocation();
  const { account } = useSelector((state: RootState) => state.account);
  const { classesList } = useSelector((state: RootState) => state.teacher);
  const dispatch = useDispatch();

  useEffect(() => {
    if (account?.schoolID && account?.role === 'teacher') {
      dispatch(getTeacherClasses(account?.schoolID, account?.UID));
    }
  }, [account, dispatch]);

  const { isCollapse, collapseClick, collapseHover, onHoverEnter, onHoverLeave } =
    useCollapseDrawer();

  const { currentLang, translateGender } = useLocales();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column'
        }
      }}
    >
      <Stack
        spacing={3}
        sx={{
          px: 2.5,
          pt: 3,
          pb: 2,
          ...(isCollapse && {
            alignItems: 'center'
          })
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box component={RouterLink} to="/" sx={{ display: 'inline-flex' }}>
            <LogoFull />
          </Box>

          {/* <MHidden width="lgDown">
            {!isCollapse && (
              <IconCollapse onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />
            )}
          </MHidden> */}
        </Stack>

        {isCollapse ? (
          <MyAvatar sx={{ mx: 'auto', mb: 2 }} />
        ) : (
          <Link underline="none" component={RouterLink} to={PATH_DASHBOARD.user.account}>
            <AccountStyle>
              <MyAvatar />
              <Box sx={{ ml: 2 }}>
                <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                  {account?.displayName}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {currentLang.value === 'ar' ? account?.schoolName : account?.schoolNameEn}
                </Typography>
                <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                  {translateGender(`DashboardSidebar.${account?.role}`, account?.gender)}
                </Typography>
              </Box>
            </AccountStyle>
          </Link>
        )}
      </Stack>
      {account?.role === 'admin' && (
        <NavSection navConfig={sidebarConfigAdmin} isShow={!isCollapse} />
      )}
      {account?.role === 'teacher' && (
        <NavSection
          navConfig={[
            {
              subheader: 'reports',
              items: [
                {
                  title: 'summary',
                  path: PATH_DASHBOARD.general.app,
                  icon: <Icon icon="la:chalkboard-teacher" height="30" width="30" />
                },
                {
                  title: 'Build Report',
                  path: `/dashboard/lessons`,
                  icon: <Icon icon="carbon:report-data" height="40" width="40" />
                }
              ]
            },
            {
              subheader: 'classes',
              items: classesList.map((data) => ({
                title: data.name,
                path: `/dashboard/class/${data.classID}`,
                icon: (
                  <Avatar
                    alt={data.name}
                    src={classAvatarURL(data.imageURL)}
                    sx={{
                      width: 30,
                      height: 30
                    }}
                  />
                ),
                classID: data.classID
              }))
            }
          ]}
          isShow={!isCollapse}
        />
      )}
      {account?.role === 'super' && (
        <NavSection navConfig={sidebarConfigSupervisor} isShow={!isCollapse} />
      )}
      {/* {account?.role !== 'admin' && account?.role !== 'teacher' && ( */}
      {account?.role === 'student' && (
        <NavSection navConfig={sidebarConfigStudent} isShow={!isCollapse} />
      )}

      <Box sx={{ flexGrow: 1 }} />

      {!isCollapse && (
        <Stack
          spacing={3}
          alignItems="center"
          sx={{ px: 5, pb: 5, mt: 10, width: 1, textAlign: 'center' }}
        >
          <img alt="register" src="/static/illustrations/illustration_sidebar.svg" />
          {/* <DocIllustration sx={{ width: 1 }} /> */}
        </Stack>
      )}
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? COLLAPSE_WIDTH : DRAWER_WIDTH
        },
        ...(collapseClick && {
          position: 'absolute'
        })
      }}
    >
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              ...(isCollapse && {
                width: COLLAPSE_WIDTH
              }),
              ...(collapseHover && {
                borderRight: 0,
                backdropFilter: 'blur(6px)',
                WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
                boxShadow: (theme) => theme.customShadows.z20,
                bgcolor: (theme) => alpha(theme.palette.background.default, 0.88)
              })
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
