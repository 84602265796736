import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { School } from '../../@types/school';

// ----------------------------------------------------------------------

type SchoolState = {
  isLoading: boolean;
  error: boolean;
  school: null | School;
};

const initialState: SchoolState = {
  isLoading: false,
  error: false,
  school: null
};

const slice = createSlice({
  name: 'school',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET/SET School
    getSchoolSuccess(state, action) {
      state.isLoading = false;
      state.school = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getSchool(schoolID: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      console.log('getSchool');
      const response = await axios.get(`getSchoolInfo/${schoolID}`);
      dispatch(slice.actions.getSchoolSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setSchool(school: School) {
  return new Promise(async (resolve) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`updateSchoolInfo/${school.schoolID}`, {
        name: school.name,
        nameEn: school.nameEn,
        country: school.country,
        email: school.email,
        phoneNo: school.phoneNo
      });
      dispatch(slice.actions.getSchoolSuccess(school));
      resolve(school.schoolID);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  });
}
