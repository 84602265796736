import { CircularProgress, Box, Typography } from '@mui/material';

export default function Spinner() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexFlow: 'column',
        margin: '0 auto',
        width: 'fit-content'
      }}
    >
      <CircularProgress color="secondary" />
      <Typography mt={2} variant="body1" color="secondary.main" align="center">
        يرجى الانتظار...
      </Typography>
    </Box>
  );
}
