import { createSlice } from '@reduxjs/toolkit';
import { omit } from 'lodash';
// utils
import axios from '../../utils/axios';
import { EnrollCard, EnrollColumn } from '../../@types/enroll';
import { dispatch } from '../store';
import { BarChartOutlined } from '@mui/icons-material';
// ----------------------------------------------------------------------

function objFromArray<Type extends Record<string, any>>(array: Type[], key: string = 'id') {
  return array.reduce<Record<string, Type>>((accumulator, current) => {
    accumulator[current[key]] = current;
    return accumulator;
  }, {});
}

type InitialState = {
  isLoading: boolean;
  error: boolean;
  board: {
    cards: Record<string, EnrollCard>;
    columns: Record<string, EnrollColumn>;
    columnOrder: string[];
  };
};

const initialState: InitialState = {
  isLoading: false,
  error: false,
  board: {
    cards: {},
    columns: {},
    columnOrder: []
  }
};

const slice = createSlice({
  name: 'enroll',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET BOARD
    getBoardSuccess(state, action) {
      state.isLoading = false;
      const columns = objFromArray<EnrollColumn>([
        {
          id: '0',
          name: 'unenrolled',
          cardIds: []
        },
        {
          id: '1',
          name: 'enrolled',
          cardIds: []
        }
      ]);
      const { classID, role, cards } = action.payload;
      console.log(role);
      for (let c of cards) {
        c.id = c.serialID.toString();
        if (role === 'student') {
          if (c.classID === classID) {
            columns['1'].cardIds.push(c.id);
          } else if (!c.classID || c.classID === 0) {
            columns['0'].cardIds.push(c.id);
          }
        } else {
          if (c.classID === classID) {
            console.log(c.id);
            if (!columns['1'].cardIds.includes(c.id)) {
              columns['1'].cardIds.push(c.id);
            }
            // remove it from other side
            const i = columns['0'].cardIds.indexOf(c.id);
            if (i >= 0) {
              columns['0'].cardIds.splice(i, 1);
            }
          } else {
            // only added if it is not already added in either column
            if (!columns['1'].cardIds.includes(c.id) && !columns['0'].cardIds.includes(c.id)) {
              columns['0'].cardIds.push(c.id);
            }
          }
        }
      }
      state.board = {
        cards: objFromArray<EnrollCard>(cards),
        columns,
        columnOrder: ['0', '1']
      };
    },

    setBoardSuccess(state) {
      state.isLoading = false;
    },

    // CREATE NEW COLUMN
    createColumnSuccess(state, action) {
      const newColumn = action.payload;
      state.isLoading = false;
      state.board.columns = {
        ...state.board.columns,
        [newColumn.id]: newColumn
      };
      state.board.columnOrder.push(newColumn.id);
    },

    persistCard(state, action) {
      const columns = action.payload;
      state.board.columns = columns;
    },

    persistColumn(state, action) {
      state.board.columnOrder = action.payload;
    },

    addTask(state, action) {
      const { card, columnId } = action.payload;

      state.board.cards[card.id] = card;
      state.board.columns[columnId].cardIds.push(card.id);
    },

    deleteTask(state, action) {
      const { cardId, columnId } = action.payload;

      state.board.columns[columnId].cardIds = state.board.columns[columnId].cardIds.filter(
        (id) => id !== cardId
      );
      state.board.cards = omit(state.board.cards, [cardId]);
    },

    // UPDATE COLUMN
    updateColumnSuccess(state, action) {
      const column = action.payload;

      state.isLoading = false;
      state.board.columns[column.id] = column;
    },

    // DELETE COLUMN
    deleteColumnSuccess(state, action) {
      const { columnId } = action.payload;
      const deletedColumn = state.board.columns[columnId];

      state.isLoading = false;
      state.board.columns = omit(state.board.columns, [columnId]);
      state.board.cards = omit(state.board.cards, [...deletedColumn.cardIds]);
      state.board.columnOrder = state.board.columnOrder.filter((c) => c !== columnId);
    }
  }
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------

export function getBoard(schoolID: number, classID: number, role: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`getSchoolClassData/${schoolID}/${role}`);
      dispatch(
        slice.actions.getBoardSuccess({
          classID,
          role,
          cards: response.data
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

function isEnrolled(board: any, card: EnrollCard) {
  for (let c of board.columns['1'].cardIds) {
    if (c === card.id) {
      return true;
    }
  }
  return false;
}

function updateCards(classID: number, board: any) {
  var cards = [];
  // should clone not just copy reference
  // only clonning enrolled users
  for (let key in board.cards) {
    let card = { ...board.cards[key] };
    if (isEnrolled(board, card)) {
      card.classID = classID;
      delete card.id;
      cards.push(card);
    }
  }
  return cards;
}

// export function setBoard(classID: number, role: string, board: any) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const cards = updateCards(classID, board);
//       const response = await axios.post(`updateSchoolClassData/${classID}/${role}`, cards);
//       console.log(response.data);
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }
// setBoard with promise
export function setBoard(classID: number, role: string, board: any) {
  return new Promise(async (resolve) => {
    dispatch(slice.actions.startLoading());
    try {
      const cards = updateCards(classID, board);
      // const json = JSON.stringify(cards);
      // console.log(json);
      const response = await axios.post(`updateSchoolClassData/${classID}/${role}`, cards);
      dispatch(slice.actions.setBoardSuccess());
      resolve(response.data.error);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  });
}

// ----------------------------------------------------------------------

export function createColumn(newColumn: { name: string }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/kanban/columns/new', newColumn);
      dispatch(slice.actions.createColumnSuccess(response.data.column));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateColumn(columnId: string, updateColumn: EnrollColumn) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/kanban/columns/update', {
        columnId,
        updateColumn
      });
      dispatch(slice.actions.updateColumnSuccess(response.data.column));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteColumn(columnId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post('/api/kanban/columns/delete', { columnId });
      dispatch(slice.actions.deleteColumnSuccess({ columnId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function persistColumn(newColumnOrder: string[]) {
  return () => {
    dispatch(slice.actions.persistColumn(newColumnOrder));
  };
}

// ----------------------------------------------------------------------

export function persistCard(columns: Record<string, EnrollColumn>) {
  return () => {
    dispatch(slice.actions.persistCard(columns));
  };
}

// ----------------------------------------------------------------------

export function addTask({ card, columnId }: { card: Partial<EnrollCard>; columnId: string }) {
  return () => {
    dispatch(slice.actions.addTask({ card, columnId }));
  };
}

// ----------------------------------------------------------------------

export function deleteTask({ cardId, columnId }: { cardId: string; columnId: string }) {
  return () => {
    dispatch(slice.actions.deleteTask({ cardId, columnId }));
  };
}
