import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { Subscription } from '../../@types/subscription';

// ----------------------------------------------------------------------

type SubscriptionState = {
  isLoading: boolean;
  error: number | null;
  subscription: null | Subscription;
};

const initialState: SubscriptionState = {
  isLoading: false,
  error: null,
  subscription: null
};

const slice = createSlice({
  name: 'Subscription',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET/SET
    getSuccess(state, action) {
      state.isLoading = false;
      state.subscription = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getSubscription(UID: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const date = new Date();
      console.log('getSubscription');
      const response = await axios.get(`getSubscription/${UID}`);
      const { validUntil } = response.data;
      if (validUntil && validUntil > date.toISOString().slice(0, 10)) {
        let data = response.data;
        dispatch(
          slice.actions.getSuccess({
            isActive: true,
            validUntil: new Date(data.validUntil),
            schoolID: data.schoolID,
            schoolName: data.schoolName,
            schoolNameEn: data.schoolNameEn,
            role: data.role
          })
        );
      } else {
        dispatch(slice.actions.getSuccess({ isActive: false }));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(2));
    }
  };
}

export function resetError() {
  dispatch(slice.actions.hasError(null));
}

export function setSubscription(UID: string, promo: string) {
  return new Promise(async (resolve) => {
    dispatch(slice.actions.startLoading());
    try {
      const date = new Date();
      const response = await axios.post('newSubscription', { UID, promoCode: promo });
      if (
        response.data.hasOwnProperty('validUntil') &&
        response.data.validUntil > date.toISOString().slice(0, 10)
      ) {
        let data = response.data;
        dispatch(
          slice.actions.getSuccess({
            isActive: true,
            validUntil: new Date(data.validUntil),
            schoolID: data.schoolID,
            schoolName: data.schoolName,
            role: data.role
          })
        );
        dispatch(slice.actions.hasError(0));
        resolve(0);
      } else {
        dispatch(slice.actions.hasError(1));
        resolve(1);
      }
    } catch (error: any) {
      if (error.message === 'Promo code is already used by another user') {
        dispatch(slice.actions.hasError(3));
        resolve(3);
      } else {
        dispatch(slice.actions.hasError(2));
        resolve(2);
      }
    }
  });
}
