export const AVATARS_LIST = [
  { name: '1', fileName: '1.png' },
  { name: '2', fileName: '2.png' },
  { name: '3', fileName: '3.png' },
  { name: '4', fileName: '4.png' },
  { name: '5', fileName: '5.png' },
  { name: '6', fileName: '6.png' },
  { name: '7', fileName: '7.png' },
  { name: '8', fileName: '8.png' },
  { name: '9', fileName: '9.png' },
  { name: '10', fileName: '10.png' },
  { name: '11', fileName: '11.png' },
  { name: '12', fileName: '12.png' },
  { name: '13', fileName: '13.png' },
  { name: '14', fileName: '14.png' },
  { name: '15', fileName: '15.png' },
  { name: '16', fileName: '16.png' },
  { name: '17', fileName: '17.png' },
  { name: '18', fileName: '18.png' },
  { name: '19', fileName: '19.png' },
  { name: '20', fileName: '20.png' },
  { name: '21', fileName: '21.png' },
  { name: '22', fileName: '22.png' },
  { name: '23', fileName: '23.png' },
  { name: '24', fileName: '24.png' },
  { name: '25', fileName: '25.png' },
  { name: '26', fileName: '26.png' },
  { name: '27', fileName: '27.png' },
  { name: '28', fileName: '28.png' },
  { name: '29', fileName: '29.png' },
  { name: '30', fileName: '30.png' },
  { name: '31', fileName: '31.png' },
  { name: '32', fileName: '32.png' },
  { name: '33', fileName: '33.png' },
  { name: '34', fileName: '34.png' },
  { name: '35', fileName: '35.png' },
  { name: '36', fileName: '36.png' },
  { name: '37', fileName: '37.png' },
  { name: '38', fileName: '38.png' },
  { name: '', fileName: '' }
];

export const CLASSES_AVATAR_LIST = [
  { name: '1', fileName: '1.png' },
  { name: '2', fileName: '2.png' },
  { name: '3', fileName: '3.png' },
  { name: '4', fileName: '4.png' },
  { name: '5', fileName: '5.png' },
  { name: '6', fileName: '6.png' },
  { name: '7', fileName: '7.png' },
  { name: '8', fileName: '8.png' },
  { name: '9', fileName: '9.png' },
  { name: '10', fileName: '10.png' },
  { name: '11', fileName: '11.png' },
  { name: '12', fileName: '12.png' }
];

export function avatarURL(name: string | null | undefined, mode: string = 'user') {
  if (mode === 'user') {
    if (name) {
      if (name.includes('http')) {
        return name;
      } else return name.length > 0 ? `/static/avatars/users/${name}.png` : '';
    }
    return '';
  } else if (mode === 'class') {
    if (name) {
      return name.length > 0 ? `/static/avatars/classes/${name}.png` : '';
    }
    return '';
  }
}

export function classAvatarURL(name: string | null) {
  if (name) {
    return name.length > 0 ? `/static/avatars/classes/${name}.png` : '';
  }
  return '';
}
