import { filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { UserInfo } from '../../@types/account';
import { DammaStudent } from '../../@types/damma-user';
// ----------------------------------------------------------------------

type UserListState = {
  isLoading: boolean;
  error: boolean;
  userList: UserInfo[];
  student: DammaStudent | null;
};

const initialState: UserListState = {
  isLoading: false,
  error: false,
  userList: [],
  student: null
};

// TODO: implement appropriate reducers after finding out what the app actually needs
const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CLASSES
    getUsersSuccess(state, action) {
      state.isLoading = false;
      state.userList = action.payload;
    },

    getStudentSuccess(state, action) {
      state.isLoading = false;
      state.student = action.payload;
    },

    // CREATE CLASS
    createClass(state, action) {
      state.isLoading = false;
      state.userList.push(action.payload);
    },

    // MODIFY CLASS
    modifyClass(state, action) {
      state.isLoading = false;
    },

    // DELETE CLASS
    deleteClass(state, action) {
      state.isLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions

// ----------------------------------------------------------------------
export function getUsers(schoolID: number, limit: number, offset: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`getAllUsersBySchoolID/${schoolID}`, {
        params: { limit, offset }
      });
      const rawUsers: any[] = response.data;
      const users: UserInfo[] = [];
      rawUsers.forEach((user) => {
        const p = users.find((u) => u.UID === user.UID);
        if (p) {
          p.classID.push(user.classID);
          p.className.push(user.className);
          p.level.push(user.level);
        } else {
          const { classID, ...p } = user;
          if (classID) {
            p.classID = [classID];
            p.className = [user.className];
            p.level = [user.level];
          } else {
            p.classID = [];
            p.className = [];
            p.level = [];
          }
          users.push(p);
        }
      });
      dispatch(slice.actions.getUsersSuccess(users));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getStudent(UID: String) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      console.log('getStudent');
      const response = await axios.get(`users/${UID}`);
      dispatch(slice.actions.getStudentSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function createClass(newClass: UserInfo) {
  // return async () => {
  //   dispatch(slice.actions.startLoading());
  //   try {
  //     console.log(newClass);
  //     const response = await axios.post('addClass', newClass);
  //     const r = response.data;
  //     r.classID = r.id;
  //     dispatch(slice.actions.createClass(r));
  //   } catch (error) {
  //     dispatch(slice.actions.hasError(error));
  //   }
  // };
}

// ----------------------------------------------------------------------

export function modifyUser(newClass: UserInfo) {
  // return async () => {
  //   dispatch(slice.actions.startLoading());
  //   try {
  //     console.log(newClass);
  //     const response = await axios.put(`classes/${newClass.classID}`, newClass);
  //     const r = response.data;
  //     dispatch(slice.actions.modifyClass(r));
  //   } catch (error) {
  //     dispatch(slice.actions.hasError(error));
  //   }
  // };
}

// ----------------------------------------------------------------------

export function deleteClass(userID: string) {
  // return async () => {
  //   dispatch(slice.actions.startLoading());
  //   try {
  //     const response = await axios.delete(`classes/${classID}`);
  //     dispatch(slice.actions.deleteClass(classID));
  //   } catch (error) {
  //     dispatch(slice.actions.hasError(error));
  //   }
  // };
}
// ----------------------------------------------------------------------
