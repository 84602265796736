import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from 'utils/axios';
// types
import { DammaLesson } from '../../@types/damma-lessons';
import { StudentLessonAttempt } from '../../@types/damma-reports';
import { StudentStatistics } from '../../@types/student-statistics';
// ----------------------------------------------------------------------

type UsersListState = {
  isLoading: boolean;
  isLoadingLatestLessons: boolean;
  error: boolean;
  studentLessonsList: StudentLessonAttempt[];
  studentStatistics: StudentStatistics | null;
  lessonsList: DammaLesson[];
  studentLesson: StudentLessonAttempt | null;
};

const initialState: UsersListState = {
  isLoading: false,
  isLoadingLatestLessons: false,
  error: false,
  studentLessonsList: [],
  studentStatistics: null,
  lessonsList: [],
  studentLesson: null
};

// TODO: implement appropriate reducers after finding out what the app actually needs
const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.error = false;
    },

    startLoadingLatestLesson(state) {
      state.isLoadingLatestLessons = true;
      state.error = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET LatestLessons
    getLatestLessonsSuccess(state, action) {
      state.isLoadingLatestLessons = false;
      state.studentLessonsList = action.payload;
    },

    // GET STUDENT STATISTICS
    getSuccess(state, action) {
      state.isLoading = false;
      state.studentStatistics = action.payload;
    },

    // GET All Lessons Success
    getAllLessonsSuccess(state, action) {
      state.lessonsList = action.payload;
    },

    // GET Lesson Statistics
    getLessonSuccess(state, action) {
      state.isLoading = false;
      state.studentLesson = action.payload;
    },

    //DELETE
    deleteDataSuccess(state, _action) {
      state.isLoading = false;
    }
  }
});

// Reducer
export default slice.reducer;

// USER'S Lessons
export function getLatestLessons(UUID: string, LessonsLimits: number | null) {
  return async () => {
    dispatch(slice.actions.startLoadingLatestLesson());
    try {
      console.log('getLatestLessons');
      let url = `getLatestLessons/${UUID}/${LessonsLimits}`;
      if (LessonsLimits === null) {
        url = `getLatestLessons/${UUID}/*`;
      }
      const response = await axios.get(url);
      const res = response.data.sort(function (a: any, b: any) {
        return b.date.localeCompare(a.date);
      });
      dispatch(slice.actions.getLatestLessonsSuccess(res));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// USER'S GENERAL STATISTICS
export function getUserStatistics(UUID: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      console.log('getUserStatistics');
      const response = await axios.get(`getUserStatistics/${UUID}/7`);
      console.log('getUserStatistics', UUID, response.data);
      dispatch(slice.actions.getSuccess(response.data));
    } catch (error) {
      console.log('Error:', error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// LIST ALL LESSONS in db
export function getAllLessons() {
  return async () => {
    try {
      console.log('getAllLessons');
      const response = await axios.get(`getAllLessons`);
      dispatch(slice.actions.getAllLessonsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// GET LESSON STATISTICS FOR SPECIFIC USER
export function getLessonStatistics(UUID: string, LessonID: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      console.log('getLessonStatistics');
      const response = await axios.get(`getLessonStatistics/${UUID}/${LessonID}`);
      dispatch(slice.actions.getLessonSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// TESTER API
export function deleteData(
  UUID: string,
  NumberOfHours: number | string,
  LessonID: any,
  Subject: string
) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      console.log('delete');
      const response = await axios.delete(
        `deleteUserStats/${UUID}/${NumberOfHours}/${LessonID}/${Subject}`
      );
      dispatch(slice.actions.deleteDataSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
