import { useTranslation } from 'react-i18next';
// material
import { enUS, arEG } from '@mui/material/locale';

// ----------------------------------------------------------------------

const LANGS = [
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: '/static/icons/ic_flag_en.svg'
  },
  // {
  //   label: 'German',
  //   value: 'de',
  //   systemValue: deDE,
  //   icon: '/static/icons/ic_flag_de.svg'
  // },
  // {
  //   label: 'French',
  //   value: 'fr',
  //   systemValue: frFR,
  //   icon: '/static/icons/ic_flag_fr.svg'
  // },
  {
    label: 'Arabic',
    value: 'ar',
    systemValue: arEG,
    icon: '/static/icons/ic_flag_ar.svg'
  }
];

export default function useLocales() {
  const { i18n, t: translate } = useTranslation();
  const langStorage = localStorage.getItem('i18nextLng');
  const currentLang = LANGS.find((_lang) => _lang.value === langStorage) || LANGS[1];

  const handleChangeLanguage = (newlang: string) => {
    i18n.changeLanguage(newlang);
  };

  const translateGender = (text: string, gender: string | undefined) => {
    let p = text.indexOf('.');
    let sub = text.substring(p + 1);
    if (sub.length > 0 && sub !== 'undefined' && sub !== 'null') {
      if (gender) {
        if (gender === 'female') {
          return translate(text + '2');
        } else {
          return translate(text + '1');
        }
      } else {
        return translate(text);
      }
    } else {
      return '';
    }
  };

  const translateSection = (section: string) => {
    let obj = translate(section, { returnObjects: true });
    let keys: any = Object.keys(obj);
    let result = [];
    for (const element of keys) {
      let val = {
        key: element,
        value: obj[element]
      };
      result.push(val);
    }
    return result;
  };

  return {
    onChangeLang: handleChangeLanguage,
    translate,
    translateGender,
    translateSection,
    currentLang,
    allLang: LANGS
  };
}
